import { Model, strict, act, flow, Flows } from 'agent-reducer';
import { uiPrompt } from '@/avatars';
import userConfigRequest from '@/requests/userConfig';
import { Result } from '@/components/works/tradeStatusEditor/type';
import { State } from './type';
import { rulesToConfigs } from '@/components/works/remindMainStrategyDialog/components/afterSale/util';
import {
  FatigueConfigWebUpdateCmd,
  OfficeTimeConfigWebUpdateCmd
} from '@/requests/userConfig/type';
import { tradeStatusList } from '@/components/works/tradeStatusEditor/constants';
import { getPlatform } from '@/services/platform';

@strict()
export class GeneralSettingModel implements Model<State> {
  state: State = {
    loading: false,
    afterSaleConfig: {
      afterSaleDay: 30,
      statusConfigs: [
        { tradeStatus: 'WAIT_SEND' },
        { tradeStatus: 'SELLER_CONSIGNED_PART' },
        { tradeStatus: 'SENT' },
        { tradeStatus: 'SUCCESS' }
      ]
    },
    fatigueConfig: {
      active: false,
      timeLimit: {
        unit: 'DAYS',
        value: 1
      },
      maxTimes: 3,
      excludeTypes: []
    },
    remindTypes: [],
    officeTimeConfig: {
      startTime: getPlatform().isJD ? '08:00:00' : '00:00:00',
      endTime: '23:59:59',
      includeTypes: [],
      taskHandleType: 'DELAY'
    },
    validReplyConfig: {
      unit: 'DAYS',
      value: 3
    },
    silenceDayConfig: {
      silenceDay: 2,
    }
  };

  @act()
  private load(): State {
    return { ...this.state, loading: true };
  }

  @act()
  private unload(): State {
    return { ...this.state, loading: false };
  }

  @act()
  private changeState(newState: Partial<State>): State {
    return { ...this.state, ...newState };
  }

  @flow()
  async fetchConfig(): Promise<void> {
    this.load();
    try {
      const [
        afterSaleConfig,
        remindTypes,
        fatigueConfig,
        officeTimeConfig,
        validReplyConfig,
        silenceDayConfig,
      ] = await Promise.all([
        userConfigRequest.afterSaleConfig(),
        userConfigRequest.remindTypes(),
        userConfigRequest.fatigueConfig(),
        userConfigRequest.officeTimeConfig(),
        userConfigRequest.validReplyConfig(),
        userConfigRequest.silenceDayConfig(),
      ]);
      this.changeState({
        afterSaleConfig: {
          ...afterSaleConfig,
          statusConfigs: afterSaleConfig.statusConfigs.filter(v =>
            tradeStatusList.includes(v.tradeStatus)
          )
        },
        remindTypes: remindTypes.filter(i => i.code !== 'TRANSFER_WORD_PUSH'),
        fatigueConfig,
        officeTimeConfig,
        validReplyConfig,
        silenceDayConfig
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unload();
    }
  }

  @flow()
  async saveAfterSaleConfig(res: Result): Promise<void> {
    await userConfigRequest.modifyAfterSaleConfig({
      afterSaleDay: res.tradeDay,
      statusConfigs: rulesToConfigs(res.tradeStatusRules)
    });
    const [afterSaleConfig] = await Promise.all([
      userConfigRequest.afterSaleConfig()
    ]);
    this.changeState({ afterSaleConfig });
  }

  @flow()
  async saveFatigue(params: FatigueConfigWebUpdateCmd): Promise<void> {
    await userConfigRequest.modifyFatigueConfig(params);
    const fatigueConfig = await userConfigRequest.fatigueConfig();
    this.changeState({ fatigueConfig });
  }

  @flow()
  async saveOfficeTimeConfig(
    params: OfficeTimeConfigWebUpdateCmd
  ): Promise<void> {
    await userConfigRequest.modifyOfficeTimeConfig(params);
    const officeTimeConfig = await userConfigRequest.officeTimeConfig();
    this.changeState({ officeTimeConfig });
  }

  @flow(Flows.latest())
  async fetchValidReplyConfig(): Promise<void> {
    const validReplyConfig = await userConfigRequest.validReplyConfig();
    this.changeState({ validReplyConfig });
  }

  @flow(Flows.latest())
  async fetchSilenceDayConfig(): Promise<void> {
    const silenceDayConfig = await userConfigRequest.silenceDayConfig();
    this.changeState({ silenceDayConfig });
  }
}
