import React from 'react';
import { usePlatform } from '@/hooks/usePlatform';
import { Icon } from '@/components/widgets/icon';
import { FlagFilled } from '@ant-design/icons/lib/icons';

type FlagsWithPlatformProps = {
  color: string;
};

export const FlagsWithPlatform = (props: FlagsWithPlatformProps) => {
  const { color } = props;
  const { platform } = usePlatform();

  const icon = { JD: 'qizhi2', DD: 'qizhi1' }[platform];

  if (platform !== 'TB') {
    return <Icon type={icon} style={{ color }} />;
  }

  return <FlagFilled style={{ color }} />;
};
