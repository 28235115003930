import React, { memo, useState } from 'react';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { SilenceDayDialogProps } from './type';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';
import userConfigRequest from '@/requests/userConfig';
import useRequest from '@/hooks/useRequest';
import { Spin } from '@/components/widgets/spin';
import { InputNumber } from '@/components/widgets/input';
import { SilenceConfig } from '@/requests/userConfig/type';

export const SilenceDayDialog = memo(
  (props: DialogDeployProps<SilenceDayDialogProps>) => {
    const { closeDialog, params } = props;
    const { onOk } = params;
    const [state, setState] = useState<SilenceConfig>();

    const { loading: fetchLoading } = useRequest(
      () => userConfigRequest.silenceDayConfig(),
      {
        onError: warnError,
        onSuccess: v => {
          setState(v);
        }
      }
    );

    const { run: updateSilenceDayConfig, loading: updateLoading } = useRequest(
      (config: SilenceConfig) => userConfigRequest.updateSilenceDayConfig(config),
      {
        manual: true,
        onError: warnError,
        onSuccess: v => {
          message.success('保存成功');
          onOk?.();
          closeDialog(true);
        }
      }
    );

    const handleOk = async () => {
      debugger
      if (!state?.silenceDay || state.silenceDay < 1 || state.silenceDay > 7 || !Number.isInteger(state.silenceDay)) {
        message.warning('请输入1~7的整数');
        return;
      }
      updateSilenceDayConfig(state!);
    };

    const handleChange = silenceDay => {
      setState({
        silenceDay
      });
    };

    if (!state) {
      return null;
    }

    return (
      <Dialog
        title="静默买家判定规则"
        onCancel={closeDialog}
        bodyStyle={{ padding: '24px' }}
        onOk={handleOk}
      >
        <Spin spinning={fetchLoading || updateLoading}>
          <div>
            <InputNumber
              value={state.silenceDay}
              onChange={handleChange}
            />&nbsp;
            自然天内没有买家/卖家消息，该买家视为静默
          </div>

          <div style={{ color: '#999', marginTop: '18px' }}>如设置2自然天，则今天和昨天没有消息，即视为静默。</div>
        </Spin>
      </Dialog>
    );
  }
);
