import {
  middleWare,
  MiddleWarePresets,
  OriginAgent,
  sharing
} from 'agent-reducer';
import shopRequest from '@/requests/shop';
import { AccountState } from './type';
import { flatMap, groupBy } from 'lodash';
import { getAccountLabel } from '@/hooks/useCustomerNames';

class AccountModel implements OriginAgent<AccountState> {
  state: AccountState = {
    account: [],
    accountMap: new Map([])
  };

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async initial(): Promise<AccountState> {
    return this.fetchAccount();
  }

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async fetchAccount(): Promise<AccountState> {
    const account = await shopRequest.getAccountList();
    const idMap = new Map(
      account.length ? account.map((v) => [v.id, getAccountLabel(v)]) : []
    );
    const labelMap = new Map(
      account.length ? account.map((v) => [getAccountLabel(v), v.id]) : []
    );
    return {
      account,
      accountMap: new Map([...idMap, ...labelMap])
    };
  }
}
export const accountRef = sharing(() => AccountModel);
